
        <nz-tabset>
            <nz-tab [nzTitle]="model[i][field.fieldArray.templateOptions['tabNameKey']]"
                    *ngFor="let sub_field of field.fieldGroup; let i = index;"
                    [ngClass]="field.fieldArray.templateOptions['filedGroupClassName']">
                <formly-group
                        [ngClass]="field.fieldArray.templateOptions['filedGroupItemClassName']"
                        [model]="model[i]"
                        [field]="sub_field"
                        [options]="options"
                        [form]="formControl">
                </formly-group>
            </nz-tab>
        </nz-tabset>
    