/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/ng-zorro-antd/tabs/ng-zorro-antd-tabs.ngfactory";
import * as i2 from "@angular/common";
import * as i3 from "ng-zorro-antd/tabs";
import * as i4 from "../../../../node_modules/@ngx-formly/core/ngx-formly-core.ngfactory";
import * as i5 from "@ngx-formly/core";
import * as i6 from "ng-zorro-antd/core";
import * as i7 from "@angular/router";
import * as i8 from "./repeat-tabs";
var styles_RepeatTabsTypeComponent = [];
var RenderType_RepeatTabsTypeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RepeatTabsTypeComponent, data: {} });
export { RenderType_RepeatTabsTypeComponent as RenderType_RepeatTabsTypeComponent };
function View_RepeatTabsTypeComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "nz-tab", [], null, null, null, i1.View_NzTabComponent_0, i1.RenderType_NzTabComponent)), i0.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵdid(3, 704512, [[1, 4]], 2, i3.NzTabComponent, [i0.ElementRef, i0.Renderer2], { nzTitle: [0, "nzTitle"] }, null), i0.ɵqud(603979776, 2, { template: 0 }), i0.ɵqud(603979776, 3, { linkDirective: 0 }), (_l()(), i0.ɵeld(6, 0, null, 1, 3, "formly-group", [], null, null, null, i4.View_ɵc_0, i4.RenderType_ɵc)), i0.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(8, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵdid(9, 16760832, null, 0, i5.ɵc, [], { form: [0, "form"], field: [1, "field"], model: [2, "model"], options: [3, "options"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.field.fieldArray.templateOptions["filedGroupClassName"]; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.model[_v.context.index][_co.field.fieldArray.templateOptions["tabNameKey"]]; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.field.fieldArray.templateOptions["filedGroupItemClassName"]; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.formControl; var currVal_4 = _v.context.$implicit; var currVal_5 = _co.model[_v.context.index]; var currVal_6 = _co.options; _ck(_v, 9, 0, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_RepeatTabsTypeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "nz-tabset", [], null, null, null, i1.View_NzTabSetComponent_0, i1.RenderType_NzTabSetComponent)), i0.ɵprd(512, null, i6.NzUpdateHostClassService, i6.NzUpdateHostClassService, [i0.RendererFactory2]), i0.ɵdid(2, 8110080, null, 1, i3.NzTabSetComponent, [i6.NzConfigService, i0.Renderer2, i6.NzUpdateHostClassService, i0.ElementRef, i0.ChangeDetectorRef, [2, i7.Router]], null, null), i0.ɵqud(603979776, 1, { listOfNzTabComponent: 1 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RepeatTabsTypeComponent_1)), i0.ɵdid(5, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = _co.field.fieldGroup; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_RepeatTabsTypeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "formly-repeat-tabs-section", [], null, null, null, View_RepeatTabsTypeComponent_0, RenderType_RepeatTabsTypeComponent)), i0.ɵdid(1, 16760832, null, 0, i8.RepeatTabsTypeComponent, [i5.FormlyFormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RepeatTabsTypeComponentNgFactory = i0.ɵccf("formly-repeat-tabs-section", i8.RepeatTabsTypeComponent, View_RepeatTabsTypeComponent_Host_0, { form: "form", field: "field", model: "model", options: "options" }, {}, []);
export { RepeatTabsTypeComponentNgFactory as RepeatTabsTypeComponentNgFactory };
