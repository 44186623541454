<div class="oops-header">
    <div class="warp-default">
        <div class="head-left">
            <a href="#" class="logo">
                <img src="assets/images/logo-en.svg" i18n-src>
            </a>
        </div>
        <div class="head-right">
            <ul class="topbar-nav">
                <!-- <li><a href="#">normal nav link</a></li> -->
                <li class="icon">
                    <a href="#">
                        <i class="fa fa-search"></i>
                    </a>
                </li>
                <li class="action">
                </li>
                <li class="user">
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="oops-login">
    <div class="login-container">
        <div class="login-head">
            <nav class="nav nav-login-way">
                <a class="nav-link active" href="#" i18n>outer user</a>
                <a class="nav-link" [href]="login_url" target="_self" i18n>inner user</a>
            </nav>
        </div>
        <div class="login-body" *ngIf="fields">
            <form [formGroup]="form" (ngSubmit)="submit()">
                <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form">
                    <div class="login-btn row">
                        <div class="col-md-6">
                            <a [routerLink]="['/forgot-password']" class="btn btn-link text-left"
                               i18n
                               style="margin-right: 10px;">forgot password?</a>
                        </div>
                        <div class="col-md-6">
                            <button type="submit" class="btn btn-primary btn-lg" i18n>login</button>
                        </div>
                    </div>
                </formly-form>
            </form>
        </div>
    </div>
</div>
