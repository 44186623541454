<div [formGroup]="form" [class.has-error]="showError">
    <label *ngFor="let option of to.options; let i = index;" class="ant-radio-wrapper"
           [class.ant-radio-wrapper-checked]="option.key==model[key]">
        <span class="ant-radio" [class.ant-radio-checked]="option.key==model[key]">
            <input [name]="id" [id]="id + '_' + i" type="radio" [value]="option.key" [formControl]="formControl"
                   [formlyAttributes]="field"
                   class="ant-radio-input">
            <span class="ant-radio-inner">
            </span>
        </span>
        <span>{{ option.value }}</span>
    </label>
</div>