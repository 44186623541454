var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FieldArrayType, FormlyFormBuilder } from '@ngx-formly/core';
var RepeatTabsTypeComponent = /** @class */ (function (_super) {
    __extends(RepeatTabsTypeComponent, _super);
    function RepeatTabsTypeComponent(builder) {
        return _super.call(this, builder) || this;
    }
    RepeatTabsTypeComponent.prototype.add = function () {
        _super.prototype.add.call(this);
    };
    return RepeatTabsTypeComponent;
}(FieldArrayType));
export { RepeatTabsTypeComponent };
