import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { AppRoutingModule } from './app-routing';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RetrievePasswordComponent } from './retrieve-password/retrieve-password.component';
import { CacheService } from './services/cache.service';
import { CoolLocalStorage } from "angular2-cool-storage";
import { ApiService } from "./services/api.service";
import { httpInterceptorProviders } from "./http-interceptors/index";
import { FormlyComponentModule } from "./modules/formlyComponentModule";
import { NgZorroAntdModule, NZ_I18N, zh_CN, en_US } from 'ng-zorro-antd';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ApiModule, BASE_PATH } from "../api-client";
import { environment } from "../environments/environment";

import * as Raven from 'raven-js';

if (window.location.host.includes('oops.qq.com')) {
    Raven
        .config('https://26f2bb6f7c0f4a4e8ead28fda73ac284@report.url.cn/sentry/1079',{
            release: environment.version
        })
        .install();
}

export class RavenErrorHandler implements ErrorHandler {
    handleError(err: any): void {
        if (environment.production) {
            Raven.captureException(err);
        } else {
            console.error(err);
        }
    }
}

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
import en from '@angular/common/locales/en';
import { PiwikService } from "./services/piwik.service";
import { LanguageService } from "./services/language.service";
import { RestLinkService } from "./services/rest-link.service";
import { ErrorTestComponent } from "./error-test/error-test.component";
// we use the webpack raw-loader to return the content as a string
let locale = en;
let zorro_locale: any = en_US;
if (LanguageService.language == 'zh-Hans') {
    locale = zh;
    zorro_locale = zh_CN;
}
registerLocaleData(locale);

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

declare let jQuery: Object;

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ForgotPasswordComponent,
        RetrievePasswordComponent,
        ErrorTestComponent,
    ],
    imports: [
        HttpClientModule,
        AppRoutingModule,
        FormlyComponentModule,
        NgZorroAntdModule,
        BrowserAnimationsModule,
        ApiModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        BrowserModule
    ],
    providers: [
        {provide: ErrorHandler, useClass: RavenErrorHandler},
        httpInterceptorProviders,
        CoolLocalStorage,
        PiwikService,
        ApiService,
        CacheService,
        RestLinkService,
        {provide: BASE_PATH, useValue: '.'},
        {provide: NZ_I18N, useValue: zorro_locale}
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
