var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { HttpClient } from "@angular/common/http";
var FormlyFieldMtpSingleFileComponent = /** @class */ (function (_super) {
    __extends(FormlyFieldMtpSingleFileComponent, _super);
    function FormlyFieldMtpSingleFileComponent(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.progress = 0;
        _this.valid = function (file) {
            if (file.size > 200 * 1024 * 1024) {
                _this.formControl.setErrors({
                    file_size: true,
                });
                _this.formControl.markAsTouched();
                return false;
            }
            var allow_type_list = [
                'application/vnd.android.package-archive',
                'application/zip',
                'application/x-zip-compressed',
                'image/jpg',
                'image/png',
                'image/gif',
                'image/jpeg',
                'image/bmp',
            ];
            if (!allow_type_list.includes(file.type)) {
                _this.formControl.setErrors({
                    file_type: true,
                });
                _this.formControl.markAsTouched();
                return false;
            }
            return true;
        };
        return _this;
    }
    Object.defineProperty(FormlyFieldMtpSingleFileComponent.prototype, "file", {
        get: function () {
            if (!this._file) {
                if (!this.model[this.key]) {
                    this._file = null;
                }
                else {
                    this.loadFile();
                }
            }
            return this._file;
        },
        enumerable: true,
        configurable: true
    });
    FormlyFieldMtpSingleFileComponent.prototype.loadFile = function () {
        var _this = this;
        this._file = {
            file_name: 'loading'
        };
        this.http.get('web/mtp/file-detail?id=' + this.model[this.key]).subscribe(function (result) {
            if (result['ret_code'] === 0) {
                _this._file = result['data'];
                console.log(_this._file);
            }
            else {
                _this._file = {
                    file_name: 'file error'
                };
            }
        });
    };
    FormlyFieldMtpSingleFileComponent.prototype.ngOnInit = function () {
        this.to['file_size_error_message'] = '文件大小超过200M';
        this.to['file_type_error_message'] = '文件类型仅支持.apk、.zip、.png、.jpg、.bmp、.gif等图片格式';
        this.updateForm();
    };
    FormlyFieldMtpSingleFileComponent.prototype.onChange = function ($event) {
        if ($event.type === 'progress') {
            this.progress = Math.round($event.event.percent);
        }
        if ($event.type === 'success') {
            this.progress = 0;
            this.model[this.key] = $event.file.response['data'];
            this.loadFile();
            this.updateForm();
        }
    };
    FormlyFieldMtpSingleFileComponent.prototype.updateForm = function () {
        if (this.form.get(this.key)) {
            this.form.get(this.key).setValue(this.model[this.key]);
        }
    };
    return FormlyFieldMtpSingleFileComponent;
}(FieldType));
export { FormlyFieldMtpSingleFileComponent };
