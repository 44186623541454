import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { CacheService } from "./cache.service";
import { ProductService } from "../../api-client";

@Injectable()
export class ApiService {

    constructor(private http: HttpClient,
                private productService: ProductService) {
    }

    product_list() {
        return new Promise(resolve => {
            this.productService.webProductGet().subscribe(result => {
                if (result.ret_code === 0) {
                    resolve(result['data']);
                }
            });
        });
    }

    get_product_title(product_id) {
        return this.product_list().then(function (data) {
            if (Array.isArray(data)) {
                let product = data.find(item => item.id == product_id)
                if (product) {
                    return product.title;
                }
            }
            return product_id;
        })
    }
}
