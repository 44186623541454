import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyFieldMemberPicker } from "../fields/member-picker/member-picker";
import { MemberPickerComponent } from "../directive/member-picker/member-picker.component";
import { FormlyFieldNzSelect } from "../fields/nz-select/nz-select";
import { SimditorDirective } from "../directive/simditor/simditor.directive";
import { FormlyFieldRadio } from "../fields/radio/radio";
import { FormlyFieldSimditor } from "../fields/simditor/simditor";
import { FormlyFieldDate } from "../fields/date/date";
import { FormlyFieldDateRange } from "../fields/date-range/date-range";
import { FormlyFieldCaptcha } from "../fields/captcha";
import { CaptchaService } from "../events/captcha.service";
import { FormlyFieldText } from "../fields/text";
import { FormlyFieldPre } from "../fields/pre";

import { NgZorroAntdModule, NZ_I18N, zh_CN } from 'ng-zorro-antd';
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
import { FormlyHorizontalWrapper } from '../fields/horizontal-wrapper/horizontal-wrapper';
import { RepeatTypeComponent } from "../fields/repeat/repeat";
import { RepeatTabsTypeComponent } from "../fields/repeat-tabs/repeat-tabs";
import { FormlyNgxZorroModule } from "formly-ngx-zorro";
import { FormlyFieldMtpSingleFileComponent } from "../fields/mtp-single-file/mtp-single-file";

registerLocaleData(zh);

export function maxlengthFunction(error: any, field: FormlyFieldConfig) {
    return '超出最大长度' + field.templateOptions.maxLength;
}

export function fileSizeFunction(error: any, field: FormlyFieldConfig) {
    if (field.templateOptions['file_size_error_message']) {
        return field.templateOptions['file_size_error_message'];
    }
    return '文件大小超出限制';
}

export function fileTypeFunction(error: any, field: FormlyFieldConfig) {
    if (field.templateOptions['file_type_error_message']) {
        return field.templateOptions['file_type_error_message'];
    }
    return '文件类型不匹配';
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgZorroAntdModule,
        FormlyNgxZorroModule,
        FormlyModule.forRoot({
            types: [
                {name: 'captcha', component: FormlyFieldCaptcha},
                {name: 'member-picker', component: FormlyFieldMemberPicker, "extends": 'input'},
                {
                    name: 'date',
                    component: FormlyFieldDate,
                    "extends": 'input'
                },
                {
                    name: 'date-range',
                    component: FormlyFieldDateRange,
                    "extends": 'input'
                },
                {
                    name: 'simditor',
                    component: FormlyFieldSimditor,
                },
                {
                    name: 'radio',
                    component: FormlyFieldRadio,
                },
                {
                    name: 'text',
                    component: FormlyFieldText,
                    "extends": 'input'
                },
                {
                    name: 'pre',
                    component: FormlyFieldPre,
                    "extends": 'input'
                },
                {name: 'nz-select', component: FormlyFieldNzSelect, "extends": 'input'},
                {name: 'repeat', component: RepeatTypeComponent},
                {name: 'repeat-tabs', component: RepeatTabsTypeComponent},
                {
                    name: 'mtp-single-file',
                    wrappers: ['field-wrapper'],
                    component: FormlyFieldMtpSingleFileComponent
                },
            ],
            validationMessages: [
                {
                    name: 'required',
                    message: '必填'
                },
                {
                    name: 'maxlength',
                    message: maxlengthFunction
                },
                {
                    name: 'file_type',
                    message: fileTypeFunction
                },
                {
                    name: 'file_size',
                    message: fileSizeFunction
                },
            ],
            wrappers: [
                {name: 'form-field-horizontal', component: FormlyHorizontalWrapper}
            ]
        }),
    ],
    providers: [
        CaptchaService,
        {provide: NZ_I18N, useValue: zh_CN}
    ],
    declarations: [
        FormlyFieldDate,
        FormlyFieldDateRange,
        FormlyFieldSimditor,
        FormlyFieldRadio,
        FormlyFieldText,
        FormlyFieldPre,
        SimditorDirective,
        FormlyFieldNzSelect,
        MemberPickerComponent,
        FormlyFieldMemberPicker,
        FormlyFieldCaptcha,
        FormlyHorizontalWrapper,
        RepeatTypeComponent,
        FormlyFieldMtpSingleFileComponent,
        RepeatTabsTypeComponent,
    ],
    exports: [
        FormlyFieldDate,
        FormlyFieldDateRange,
        FormlyFieldSimditor,
        FormlyFieldRadio,
        FormlyFieldText,
        FormlyFieldPre,
        SimditorDirective,
        FormlyFieldNzSelect,
        MemberPickerComponent,
        FormlyFieldMemberPicker,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FormlyModule,
        FormlyFieldCaptcha,
        RepeatTypeComponent,
        FormlyFieldMtpSingleFileComponent,
        RepeatTabsTypeComponent,
    ]
})
export class FormlyComponentModule {
}

