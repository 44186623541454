import {
    Component,
    Input,
    OnInit,
    OnChanges,
    Output,
    ViewEncapsulation,
    EventEmitter,
    ElementRef
} from '@angular/core';

import './memberinput.js';
import { CacheService } from "../../services/cache.service";
import * as jQuery from 'jquery';
import { HttpClient } from "@angular/common/http";

let $ = jQuery;

@Component({
    selector: 'app-member-picker',
    templateUrl: './template.html',
    styleUrls: ['./style.css'],
    encapsulation: ViewEncapsulation.None
})
export class MemberPickerComponent implements OnInit, OnChanges {
    private user_name_list;
    @Input() value: string;
    @Output() valueChange: EventEmitter<string>;
    $element;

    constructor(private http: HttpClient, private cache: CacheService, private el: ElementRef) {
        this.valueChange = new EventEmitter<string>();
    }

    ngOnInit() {
        const vm = this;
        this.cache.from('USER_NAME_LIST',
            () => {
                return this.http.get("web/user/name-list").toPromise().then(result => {
                    if (result['status']) {
                        return result['data'];
                    }
                });
            }).then(user_name_list => {
            this.user_name_list = user_name_list;
            this.$element = $(this.el.nativeElement).find('input');
            const options = {
                autoFocus: false
            };
            const allMemberList = [];
            const allMemberMap = {};
            user_name_list.length > 0 && user_name_list.forEach(item => {
                allMemberList.push(item[1]);
                allMemberMap[item[0]] = item[1];
            });
            this.$element.memberInput(options, vm.value, allMemberList, allMemberMap);

            this.$element.on('memberPicker', () => {
                let value = this.$element.val();
                value = value.replace(/\(.*?\)/g, '');
                vm.valueChange.emit(value);
            });
        });
    }

    ngOnChanges(changes) {
        const newValue = changes.value.currentValue;
        const oldValue = changes.value.previousValue;
        let $memberInput;
        if (this.$element) {
            $memberInput = this.$element.data('memberInput');
        }
        if ($memberInput && newValue !== oldValue && $memberInput.memberPickerValue.join(';').replace(/\(.*?\)/g, '') !== newValue) {
            if ($memberInput && $memberInput.reset) {
                $memberInput.reset();
                $memberInput.appendValue(newValue, false);
            }
        }
    }
}
