import { Component } from '@angular/core';

@Component({
    selector: 'app-error-test',
    templateUrl: './error-test.component.html',
    styleUrls: []
})
export class ErrorTestComponent {
    error;
}
