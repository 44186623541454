import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-member-picker',
    templateUrl: './member-picker.html',
    styleUrls: ['./member-picker.css']
})
export class FormlyFieldMemberPicker extends FieldType {
    value;

    ngOnInit() {
        this.value = this.model[this.key];
    }

    valueChange(value) {
        this.field.formControl.markAsTouched({
            onlySelf:true
        });
        this.formControl.setValue(value);
    }
}
