/**
 * 接口文档
 * Version: __0.0.1__
 *
 * OpenAPI spec version: 0.0.1
 * Contact: binghuiluo@tencent.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/internal/Observable';

import { ForgotPasswordForm } from '../model/forgotPasswordForm';
import { InlineResponse20013 } from '../model/inlineResponse20013';
import { InlineResponse20014 } from '../model/inlineResponse20014';
import { InlineResponse20015 } from '../model/inlineResponse20015';
import { InlineResponse20016 } from '../model/inlineResponse20016';
import { InlineResponse20017 } from '../model/inlineResponse20017';
import { InlineResponse20018 } from '../model/inlineResponse20018';
import { LoginForm } from '../model/loginForm';
import { ProductAllListProductQuery } from '../model/productAllListProductQuery';
import { RegisterForm } from '../model/registerForm';
import { SendForgotPasswordMailForm } from '../model/sendForgotPasswordMailForm';
import { User } from '../model/user';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UserService {

    protected basePath = 'http://oops.cm.com:8200';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 返回用户详情
     * 返回用户详情
     * @param username 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webUserDetailGet(username?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20014>;
    public webUserDetailGet(username?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20014>>;
    public webUserDetailGet(username?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20014>>;
    public webUserDetailGet(username?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (username !== undefined) {
            queryParameters = queryParameters.set('username', <any>username);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20014>(`${this.basePath}/web/user/detail`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 找回密码
     * 找回密码
     * @param ForgotPasswordForm 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webUserForgotPasswordPost(ForgotPasswordForm?: ForgotPasswordForm, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20015>;
    public webUserForgotPasswordPost(ForgotPasswordForm?: ForgotPasswordForm, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20015>>;
    public webUserForgotPasswordPost(ForgotPasswordForm?: ForgotPasswordForm, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20015>>;
    public webUserForgotPasswordPost(ForgotPasswordForm?: ForgotPasswordForm, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse20015>(`${this.basePath}/web/user/forgot-password`,
            ForgotPasswordForm,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 获取当前用户信息
     * 获取当前用户信息
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webUserInfoGet(observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20014>;
    public webUserInfoGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20014>>;
    public webUserInfoGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20014>>;
    public webUserInfoGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20014>(`${this.basePath}/web/user/info`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 用户登录
     * 用户登录
     * @param LoginForm 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webUserLoginPost(LoginForm?: LoginForm, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20014>;
    public webUserLoginPost(LoginForm?: LoginForm, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20014>>;
    public webUserLoginPost(LoginForm?: LoginForm, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20014>>;
    public webUserLoginPost(LoginForm?: LoginForm, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse20014>(`${this.basePath}/web/user/login`,
            LoginForm,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 用户注销
     * 用户注销
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webUserLogoutGet(observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20015>;
    public webUserLogoutGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20015>>;
    public webUserLogoutGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20015>>;
    public webUserLogoutGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20015>(`${this.basePath}/web/user/logout`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 菜单列表
     * 菜单列表
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webUserMenuListGet(observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20018>;
    public webUserMenuListGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20018>>;
    public webUserMenuListGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20018>>;
    public webUserMenuListGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20018>(`${this.basePath}/web/user/menu-list`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 返回全部用户列表
     * 返回全部用户列表
     * @param UserListQuery 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webUserPost(UserListQuery?: ProductAllListProductQuery, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20013>;
    public webUserPost(UserListQuery?: ProductAllListProductQuery, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20013>>;
    public webUserPost(UserListQuery?: ProductAllListProductQuery, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20013>>;
    public webUserPost(UserListQuery?: ProductAllListProductQuery, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse20013>(`${this.basePath}/web/user`,
            UserListQuery,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 用户注册
     * 用户注册
     * @param RegisterForm 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webUserRegisterPost(RegisterForm?: RegisterForm, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20014>;
    public webUserRegisterPost(RegisterForm?: RegisterForm, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20014>>;
    public webUserRegisterPost(RegisterForm?: RegisterForm, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20014>>;
    public webUserRegisterPost(RegisterForm?: RegisterForm, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse20014>(`${this.basePath}/web/user/register`,
            RegisterForm,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 发送忘记密码邮件
     * 发送忘记密码邮件
     * @param SendForgotPasswordMailForm 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webUserSendForgotPasswordMailPost(SendForgotPasswordMailForm?: SendForgotPasswordMailForm, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20015>;
    public webUserSendForgotPasswordMailPost(SendForgotPasswordMailForm?: SendForgotPasswordMailForm, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20015>>;
    public webUserSendForgotPasswordMailPost(SendForgotPasswordMailForm?: SendForgotPasswordMailForm, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20015>>;
    public webUserSendForgotPasswordMailPost(SendForgotPasswordMailForm?: SendForgotPasswordMailForm, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse20015>(`${this.basePath}/web/user/send-forgot-password-mail`,
            SendForgotPasswordMailForm,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 设置流程类型
     * 设置类型
     * @param type 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webUserSetWorkFlowTypePost(type?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20017>;
    public webUserSetWorkFlowTypePost(type?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20017>>;
    public webUserSetWorkFlowTypePost(type?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20017>>;
    public webUserSetWorkFlowTypePost(type?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any) };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (type !== undefined) {
            formParams = formParams.append('type', <any>type) || formParams;
        }

        return this.httpClient.post<InlineResponse20017>(`${this.basePath}/web/user/set-work-flow-type`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 更新用户信息
     * 更新用户信息
     * @param User 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webUserUpdatePost(User?: User, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20014>;
    public webUserUpdatePost(User?: User, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20014>>;
    public webUserUpdatePost(User?: User, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20014>>;
    public webUserUpdatePost(User?: User, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse20014>(`${this.basePath}/web/user/update`,
            User,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 流程类型列表
     * 流程类型列表
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webUserWorkFlowTypeListGet(observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20016>;
    public webUserWorkFlowTypeListGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20016>>;
    public webUserWorkFlowTypeListGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20016>>;
    public webUserWorkFlowTypeListGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20016>(`${this.basePath}/web/user/work-flow-type-list`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
