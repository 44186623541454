/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../node_modules/@ngx-formly/core/ngx-formly-core.ngfactory";
import * as i3 from "@ngx-formly/core";
import * as i4 from "./repeat";
var styles_RepeatTypeComponent = [];
var RenderType_RepeatTypeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RepeatTypeComponent, data: {} });
export { RenderType_RepeatTypeComponent as RenderType_RepeatTypeComponent };
function View_RepeatTypeComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "col-sm-2 d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "button", [["class", "btn btn-danger"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.remove(_v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Remove"]))], null, null); }
function View_RepeatTypeComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 5, "formly-group", [], null, null, null, i2.View_ɵc_0, i2.RenderType_ɵc)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(5, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵdid(6, 16760832, null, 0, i3.ɵc, [], { form: [0, "form"], field: [1, "field"], model: [2, "model"], options: [3, "options"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_RepeatTypeComponent_2)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.field.fieldArray.templateOptions["filedGroupClassName"]; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.field.fieldArray.templateOptions["filedGroupItemClassName"]; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.formControl; var currVal_3 = _v.context.$implicit; var currVal_4 = _co.model[_v.context.index]; var currVal_5 = _co.options; _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _v.context.$implicit.templateOptions["showRemoveButton"]; _ck(_v, 8, 0, currVal_6); }, null); }
function View_RepeatTypeComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "repeat-section-creator"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.add() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.field.fieldArray.templateOptions["btnText"]; _ck(_v, 2, 0, currVal_0); }); }
export function View_RepeatTypeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_RepeatTypeComponent_1)), i0.ɵdid(1, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RepeatTypeComponent_3)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.field.fieldGroup; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.field.fieldArray.templateOptions["showAddButton"]; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_RepeatTypeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "formly-repeat-section", [], null, null, null, View_RepeatTypeComponent_0, RenderType_RepeatTypeComponent)), i0.ɵdid(1, 16760832, null, 0, i4.RepeatTypeComponent, [i3.FormlyFormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RepeatTypeComponentNgFactory = i0.ɵccf("formly-repeat-section", i4.RepeatTypeComponent, View_RepeatTypeComponent_Host_0, { form: "form", field: "field", model: "model", options: "options" }, {}, []);
export { RepeatTypeComponentNgFactory as RepeatTypeComponentNgFactory };
