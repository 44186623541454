import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component'
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { RetrievePasswordComponent } from "./retrieve-password/retrieve-password.component";
import { ErrorTestComponent } from "./error-test/error-test.component";

const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
    },
    {
        path: 'retrieve-password',
        component: RetrievePasswordComponent,
    },
    {
        path: 'error-test',
        component: ErrorTestComponent,
    },
    {
        path: '',
        loadChildren: 'src/app/index/index.module#IndexModule'
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ],
    declarations: []
})
export class AppRoutingModule {
}
