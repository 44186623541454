var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FieldType } from '@ngx-formly/core';
var FormlyFieldMemberPicker = /** @class */ (function (_super) {
    __extends(FormlyFieldMemberPicker, _super);
    function FormlyFieldMemberPicker() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FormlyFieldMemberPicker.prototype.ngOnInit = function () {
        this.value = this.model[this.key];
    };
    FormlyFieldMemberPicker.prototype.valueChange = function (value) {
        this.field.formControl.markAsTouched({
            onlySelf: true
        });
        this.formControl.setValue(value);
    };
    return FormlyFieldMemberPicker;
}(FieldType));
export { FormlyFieldMemberPicker };
