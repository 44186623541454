/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./error-test.component";
var styles_ErrorTestComponent = [];
var RenderType_ErrorTestComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ErrorTestComponent, data: {} });
export { RenderType_ErrorTestComponent as RenderType_ErrorTestComponent };
export function View_ErrorTestComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(0, null, ["", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error.test; _ck(_v, 0, 0, currVal_0); }); }
export function View_ErrorTestComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-error-test", [], null, null, null, View_ErrorTestComponent_0, RenderType_ErrorTestComponent)), i0.ɵdid(1, 49152, null, 0, i1.ErrorTestComponent, [], null, null)], null, null); }
var ErrorTestComponentNgFactory = i0.ɵccf("app-error-test", i1.ErrorTestComponent, View_ErrorTestComponent_Host_0, {}, {}, []);
export { ErrorTestComponentNgFactory as ErrorTestComponentNgFactory };
