import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { HttpClient } from "@angular/common/http";
import { UploadFile } from "ng-zorro-antd";

@Component({
    selector: 'nz-formly-field-mtp-single-file-component',
    templateUrl: './mtp-single-file.component.html',
})
export class FormlyFieldMtpSingleFileComponent extends FieldType implements OnInit {
    constructor(
        private http: HttpClient
    ) {
        super()
    }

    private _file;
    get file() {
        if (!this._file) {
            if (!this.model[this.key]) {
                this._file = null;
            } else {
                this.loadFile();
            }
        }
        return this._file;
    }

    loadFile() {
        this._file = {
            file_name: 'loading'
        };
        this.http.get('web/mtp/file-detail?id=' + this.model[this.key]).subscribe(result => {
            if (result['ret_code'] === 0) {
                this._file = result['data'];
                console.log(this._file)
            } else {
                this._file = {
                    file_name: 'file error'
                };
            }
        });
    }

    ngOnInit() {
        this.to['file_size_error_message'] = '文件大小超过200M';
        this.to['file_type_error_message'] = '文件类型仅支持.apk、.zip、.png、.jpg、.bmp、.gif等图片格式';
        this.updateForm();
    }

    progress = 0;
    onChange($event) {
        if ($event.type === 'progress') {
            this.progress = Math.round($event.event.percent)
        }
        if ($event.type === 'success') {
            this.progress = 0;
            this.model[this.key] = $event.file.response['data'];
            this.loadFile();
            this.updateForm();
        }
    }

    public valid = (file: UploadFile) => {
        if (file.size > 200 * 1024 * 1024) {
            this.formControl.setErrors({
                file_size: true,
            });
            this.formControl.markAsTouched();
            return false;
        }
        const allow_type_list = [
            'application/vnd.android.package-archive',
            'application/zip',
            'application/x-zip-compressed',
            'image/jpg',
            'image/png',
            'image/gif',
            'image/jpeg',
            'image/bmp',
        ];
        if (!allow_type_list.includes(file.type)) {
            this.formControl.setErrors({
                file_type: true,
            });
            this.formControl.markAsTouched();
            return false;
        }
        return true;
    };

    updateForm() {
        if (this.form.get(this.key)) {
            this.form.get(this.key).setValue(this.model[this.key]);
        }
    }

}
