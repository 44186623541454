import { enableProdMode, TRANSLATIONS, TRANSLATIONS_FORMAT } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}
// use the require method provided by webpack
declare const require;
// we use the webpack raw-loader to return the content as a string
let translations;
let providers = [];

import { LanguageService } from "./app/services/language.service";

if (LanguageService.language == 'zh-Hans') {
    translations = require(`raw-loader!./locale/messages.zh.xlf`);
    providers.push(
        {provide: TRANSLATIONS, useValue: translations}
    );
    providers.push(
        {provide: TRANSLATIONS_FORMAT, useValue: 'xlf'}
    );
}

platformBrowserDynamic().bootstrapModule(AppModule, {providers})
    .catch(err => console.log(err));
