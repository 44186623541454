import {Component} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FormlyFormOptions, FormlyFieldConfig} from '@ngx-formly/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {CaptchaService} from "../events/captcha.service";
import {UserService} from "../../api-client";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {
    form = new FormGroup({});
    errors = [];
    model: any = {};
    options: FormlyFormOptions = {};
    captcha_refresh_captcha = 'web/user/captcha?refresh';

    fields: FormlyFieldConfig[];

    constructor(private http: HttpClient,
                private captchaService: CaptchaService,
                private userService: UserService,
                private translate: TranslateService,
                private router: Router) {

        let controller = this;
        this.fields = [
            {
                key: 'email',
                type: 'input',
                className: "username login-input-group",
                templateOptions: {
                    type: 'email',
                    required: true,
                },
                expressionProperties: {
                    'templateOptions.placeholder': function () {
                        if (!this.field.templateOptions.placeholder) {
                            let vm = this;
                            controller.translate.get('email').subscribe(result => {
                                vm.field.templateOptions.placeholder = result;
                            });
                        }
                    }
                }
            },
            {
                key: 'captcha',
                type: 'captcha',
                className: "captcha login-input-group",
                templateOptions: {
                    attributes: {
                        src: 'web/user/captcha',
                        refresh_src: this.captcha_refresh_captcha
                    }
                }
            }
        ];
    };

    submit() {
        if (this.form.valid) {
            this.errors = [];
            this.userService.webUserSendForgotPasswordMailPost(this.model)
                .subscribe(result => {
                    if (result.ret_code === 0) {
                        this.router.navigate(['/retrieve-password'], {
                            queryParams: {"email": this.model.email}
                        });
                    } else {
                        if (result['errors']) {
                            this.captchaService.refresh(this.captcha_refresh_captcha);
                            Object.keys(result['errors']).forEach(key => {
                                if (result['errors'][key] instanceof Array) {
                                    result['errors'][key].forEach(error => {
                                        this.errors.push(error);
                                    });
                                }
                            });
                        }
                    }
                });
        }
    }

}
