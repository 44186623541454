/**
 * 接口文档
 * Version: __0.0.1__
 *
 * OpenAPI spec version: 0.0.1
 * Contact: binghuiluo@tencent.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/internal/Observable';

import { InlineResponse20010 } from '../model/inlineResponse20010';
import { InlineResponse20011 } from '../model/inlineResponse20011';
import { InlineResponse2008 } from '../model/inlineResponse2008';
import { InlineResponse2009 } from '../model/inlineResponse2009';
import { ProductRole } from '../model/productRole';
import { ProductRoleListQuery } from '../model/productRoleListQuery';
import { SaveProductRoleUsers } from '../model/saveProductRoleUsers';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ProductRoleService {

    protected basePath = 'http://oops.cm.com:8200';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 获取产品角色详情
     * 获取产品角色详情
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webProductRoleDetailGet(id?: number, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20010>;
    public webProductRoleDetailGet(id?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20010>>;
    public webProductRoleDetailGet(id?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20010>>;
    public webProductRoleDetailGet(id?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20010>(`${this.basePath}/web/product-role/detail`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 获取产品角色
     * 获取产品角色
     * @param Product 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webProductRolePost(Product?: ProductRoleListQuery, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2008>;
    public webProductRolePost(Product?: ProductRoleListQuery, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2008>>;
    public webProductRolePost(Product?: ProductRoleListQuery, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2008>>;
    public webProductRolePost(Product?: ProductRoleListQuery, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse2008>(`${this.basePath}/web/product-role`,
            Product,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 保存产品角色信息
     * 保存产品角色信息
     * @param ProductRole 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webProductRoleSavePost(ProductRole?: ProductRole, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20010>;
    public webProductRoleSavePost(ProductRole?: ProductRole, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20010>>;
    public webProductRoleSavePost(ProductRole?: ProductRole, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20010>>;
    public webProductRoleSavePost(ProductRole?: ProductRole, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse20010>(`${this.basePath}/web/product-role/save`,
            ProductRole,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 保存产品角色用户列表
     * 保存产品角色用户列表
     * @param ProductRoleSaveUsers 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webProductRoleSaveUsersPost(ProductRoleSaveUsers?: SaveProductRoleUsers, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20011>;
    public webProductRoleSaveUsersPost(ProductRoleSaveUsers?: SaveProductRoleUsers, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20011>>;
    public webProductRoleSaveUsersPost(ProductRoleSaveUsers?: SaveProductRoleUsers, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20011>>;
    public webProductRoleSaveUsersPost(ProductRoleSaveUsers?: SaveProductRoleUsers, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse20011>(`${this.basePath}/web/product-role/save-users`,
            ProductRoleSaveUsers,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 获取产品角色
     * 获取产品角色
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webProductRoleStarRoleListGet(observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2009>;
    public webProductRoleStarRoleListGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2009>>;
    public webProductRoleStarRoleListGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2009>>;
    public webProductRoleStarRoleListGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse2009>(`${this.basePath}/web/product-role/star-role-list`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 获取产品角色用户列表
     * 获取产品角色用户列表
     * @param product_id 产品id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webProductRoleUsersGet(product_id: number, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20011>;
    public webProductRoleUsersGet(product_id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20011>>;
    public webProductRoleUsersGet(product_id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20011>>;
    public webProductRoleUsersGet(product_id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (product_id === null || product_id === undefined) {
            throw new Error('Required parameter product_id was null or undefined when calling webProductRoleUsersGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (product_id !== undefined) {
            queryParameters = queryParameters.set('product_id', <any>product_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20011>(`${this.basePath}/web/product-role/users`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
