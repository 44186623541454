import { ErrorHandler } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { zh_CN, en_US } from 'ng-zorro-antd';
import { environment } from "../environments/environment";
import * as Raven from 'raven-js';
if (window.location.host.includes('oops.qq.com')) {
    Raven
        .config('https://26f2bb6f7c0f4a4e8ead28fda73ac284@report.url.cn/sentry/1079', {
        release: environment.version
    })
        .install();
}
var RavenErrorHandler = /** @class */ (function () {
    function RavenErrorHandler() {
    }
    RavenErrorHandler.prototype.handleError = function (err) {
        if (environment.production) {
            Raven.captureException(err);
        }
        else {
            console.error(err);
        }
    };
    return RavenErrorHandler;
}());
export { RavenErrorHandler };
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
import en from '@angular/common/locales/en';
import { LanguageService } from "./services/language.service";
// we use the webpack raw-loader to return the content as a string
var locale = en;
var zorro_locale = en_US;
if (LanguageService.language == 'zh-Hans') {
    locale = zh;
    zorro_locale = zh_CN;
}
registerLocaleData(locale);
// AoT requires an exported function for factories
export function createTranslateLoader(http) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
var ɵ0 = (createTranslateLoader), ɵ1 = zorro_locale;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
