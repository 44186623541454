import { Injectable } from '@angular/core';
import {
    HttpInterceptor, HttpHandler,
    HttpRequest, HttpResponse
} from '@angular/common/http';

import { tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor() {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let ok: string;

        // extend server response observable with logging
        return next.handle(req)
            .pipe(
                tap(
                    // Succeeds when there is a response; ignore other events
                    event => {
                        return ok = event instanceof HttpResponse ? 'succeeded' : '';
                    },
                    // Operation failed; error is an HttpErrorResponse
                    error => {
                        if (error.status == 401) {
                            location.href = 'login?return_url=' + location.href;
                        }
                        return ok = 'failed';
                    }
                )
            );
    }
}

