import { CookieService } from "ngx-cookie-service";
var LanguageService = /** @class */ (function () {
    function LanguageService() {
    }
    Object.defineProperty(LanguageService, "storage", {
        get: function () {
            if (!LanguageService._storage) {
                LanguageService._storage = new CookieService(document);
            }
            return LanguageService._storage;
        },
        set: function (storage) {
            if (storage.get && storage.set) {
                LanguageService._storage = storage;
            }
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(LanguageService, "language", {
        get: function () {
            var language = LanguageService.storage.get('language') || navigator.language || navigator['userLanguage'];
            if (language == 'zh-CN') {
                language = 'zh-Hans';
            }
            return language;
        },
        set: function (language) {
            LanguageService.storage.set('language', language, null, '/');
            location.reload();
        },
        enumerable: true,
        configurable: true
    });
    LanguageService.languages = [
        'en-Us',
        'zh-Hans'
    ];
    LanguageService.language_text = {
        'en-Us': 'en',
        'zh-Hans': 'zh',
    };
    return LanguageService;
}());
export { LanguageService };
