
    <textarea class="form-control"
              app-simditor
              (valueChange)="valueChange($event)"
              [value]="model[key]"
           [options]="options">
    </textarea>
   <input type="hidden"
   [id]="id"
   [value]="model[key]"
   [formControl]="formControl" 
   [formlyAttributes]="field"
   >
  