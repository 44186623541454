/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./style.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./member-picker.component";
import * as i3 from "@angular/common/http";
import * as i4 from "../../services/cache.service";
var styles_MemberPickerComponent = [i0.styles];
var RenderType_MemberPickerComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_MemberPickerComponent, data: {} });
export { RenderType_MemberPickerComponent as RenderType_MemberPickerComponent };
export function View_MemberPickerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "input", [["type", "text"]], null, null, null, null, null))], null, null); }
export function View_MemberPickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-member-picker", [], null, null, null, View_MemberPickerComponent_0, RenderType_MemberPickerComponent)), i1.ɵdid(1, 638976, null, 0, i2.MemberPickerComponent, [i3.HttpClient, i4.CacheService, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MemberPickerComponentNgFactory = i1.ɵccf("app-member-picker", i2.MemberPickerComponent, View_MemberPickerComponent_Host_0, { value: "value" }, { valueChange: "valueChange" }, []);
export { MemberPickerComponentNgFactory as MemberPickerComponentNgFactory };
