import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class LanguageService {

    public static languages = [
        'en-Us',
        'zh-Hans'
    ];

    public static language_text = {
        'en-Us': 'en',
        'zh-Hans': 'zh',
    };

    private static _storage;

    static set storage(storage) {
        if (storage.get && storage.set) {
            LanguageService._storage = storage;
        }
    }

    static get storage() {
        if (!LanguageService._storage) {
            LanguageService._storage = new CookieService(document);
        }
        return LanguageService._storage;
    };

    static get language() {
        let language = LanguageService.storage.get('language') || navigator.language || navigator['userLanguage'];
        if (language == 'zh-CN') {
            language = 'zh-Hans';
        }
        return language;
    }

    static set language(language) {
        LanguageService.storage.set('language', language, null, '/');
        location.reload();
    }


}