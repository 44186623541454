
        <nz-form-item>
            <nz-form-label
                    *ngIf="to.label"
                    class="col-form-label text-right"
                    [ngClass]="to['labelClass'] || 'ant-col-4'"
                    [nzRequired]="to.required"
                    [nzFor]="id"
                    style="font-size: 12px;">
                {{ to.label }}
            </nz-form-label>
            <nz-form-control [nzValidateStatus]="showError?'error':''" [ngClass]="to['controlClass'] || 'ant-col-20'">
                <ng-template #fieldComponent></ng-template>
                <nz-form-explain *ngIf="showError">
                    <formly-validation-message [field]="field"></formly-validation-message>
                </nz-form-explain>
                <nz-form-control  [nzExtra]="to.description" *ngIf="to.description"></nz-form-control>
            </nz-form-control>
        </nz-form-item>
  