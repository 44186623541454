/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/ng-zorro-antd/date-picker/ng-zorro-antd-date-picker.ngfactory";
import * as i2 from "ng-zorro-antd/date-picker";
import * as i3 from "ng-zorro-antd/i18n";
import * as i4 from "@angular/forms";
import * as i5 from "./date-range";
import * as i6 from "@angular/common";
var styles_FormlyFieldDateRange = [];
var RenderType_FormlyFieldDateRange = i0.ɵcrt({ encapsulation: 2, styles: styles_FormlyFieldDateRange, data: {} });
export { RenderType_FormlyFieldDateRange as RenderType_FormlyFieldDateRange };
export function View_FormlyFieldDateRange_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "nz-range-picker", [["nzShowTime", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.model[_co.key] = $event) !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = (_co.onChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_NzRangePickerComponent_0, i1.RenderType_NzRangePickerComponent)), i0.ɵdid(1, 770048, null, 0, i2.NzRangePickerComponent, [i3.NzI18nService, i0.ChangeDetectorRef, i3.DateHelperService, i0.Renderer2, i0.ElementRef, [8, null]], { nzShowTime: [0, "nzShowTime"] }, null), i0.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.NzRangePickerComponent]), i0.ɵdid(3, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i0.ɵdid(5, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = ""; _ck(_v, 1, 0, currVal_7); var currVal_8 = _co.model[_co.key]; _ck(_v, 3, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 5).ngClassValid; var currVal_5 = i0.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_FormlyFieldDateRange_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "formly-field-date", [], null, null, null, View_FormlyFieldDateRange_0, RenderType_FormlyFieldDateRange)), i0.ɵdid(1, 16760832, null, 0, i5.FormlyFieldDateRange, [i6.DatePipe], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormlyFieldDateRangeNgFactory = i0.ɵccf("formly-field-date", i5.FormlyFieldDateRange, View_FormlyFieldDateRange_Host_0, { form: "form", field: "field", model: "model", options: "options" }, {}, []);
export { FormlyFieldDateRangeNgFactory as FormlyFieldDateRangeNgFactory };
