import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import * as jQuery from 'jquery';

declare let require: any;



@Directive({
    selector: '[app-simditor]',
})
export class SimditorDirective {

    private _editor: any;
    @Output()
    valueChange: EventEmitter<string> = new EventEmitter<string>();

    private _value;
    @Input()
    set value(value: any) {
        if (this._value != value) {
            this._value = value || '';
            if (this._editor) {
                this._editor.setValue(this._value);
            }
        }
    }

    @Input('options') options: any;

    constructor(private elementRef: ElementRef) {
    }

    ngAfterContentInit() {
        let $calendarElement = jQuery(this.elementRef.nativeElement);
        let options = {};
        if (this.options instanceof Object) {
            Object.assign(options, this.options);
        }
        // $calendarElement['daterangepicker'](options);
        require.ensure(['simditor'], (require) => {
            let Simditor = require('simditor');
            let editor = new Simditor({
                textarea: $calendarElement
                //optional options
            });
            let vm = this;
            if (this._value) {

                editor.setValue(this._value);
            }
            editor.on('valuechanged', function () {
                vm._value = editor.getValue();
                vm.valueChange.emit(editor.getValue());
            });
            editor.on('selectionchanged', function () {
                vm._value = editor.getValue();
                vm.valueChange.emit(editor.getValue());
            });
            this._editor = editor;
        });

    }


}
