import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { CaptchaService } from "../events/captcha.service";
import { UserService } from "../../api-client";
import { forkJoin } from "../../../node_modules/rxjs/internal/observable/forkJoin";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-retrieve-password',
    templateUrl: './retrieve-password.component.html',
    styleUrls: ['./retrieve-password.component.css']
})
export class RetrievePasswordComponent implements OnInit {
    form = new FormGroup({});
    errors = [];
    model: any = {};
    options: FormlyFormOptions = {};
    captcha_refresh_captcha = 'web/user/captcha?refresh';

    fields: FormlyFieldConfig[];

    constructor(private http: HttpClient,
                private captchaService: CaptchaService,
                private userService: UserService,
                private translate: TranslateService,
                private router: Router, private activatedRoute: ActivatedRoute) {

        forkJoin([
            this.translate.get('email'),
            this.translate.get('retrieve password ticket'),
            this.translate.get('new password'),
            this.translate.get('Weak password,must satisfy [number] [letter] [non-alphanumeric] and length greater than 6.'),
        ]).subscribe(results => {
            this.fields = [
                {
                    key: 'email',
                    type: 'input',
                    className: "username login-input-group",
                    templateOptions: {
                        placeholder: results[0],
                        type: 'email',
                        required: true,
                    },
                },
                {
                    key: 'ticket',
                    type: 'input',
                    className: "password login-input-group",
                    templateOptions: {
                        placeholder: results[1],
                        required: true,
                    },
                },
                {
                    key: 'password',
                    type: 'input',
                    className: "password login-input-group",
                    templateOptions: {
                        placeholder: results[2],
                        type: 'password',
                        required: true,
                    },
                    validators: {
                        strong: {
                            expression: (c) => {
                                return /[1-9]/.test(c.value)
                                    && /[a-z]/.test(c.value)
                                    && /[^a-z1-9]/.test(c.value)
                                    && c.value.length >= 6;
                            },
                            message: (error, field: FormlyFieldConfig) => results[3],
                        },
                    },
                },
                {
                    key: 'captcha',
                    type: 'captcha',
                    className: "captcha login-input-group",
                    templateOptions: {
                        attributes: {
                            src: 'web/user/captcha',
                            refresh_src: this.captcha_refresh_captcha
                        }
                    }
                }
            ];
        });
    };

    ngOnInit() {
        // subscribe to router event
        this.activatedRoute.queryParams.subscribe((params: Params) => {
            this.model = {
                email: params['email'],
                ticket: params['ticket'],
            };
        });
    }

    submit() {
        if (this.form.valid) {
            this.errors = [];
            this.userService.webUserForgotPasswordPost(this.model).subscribe(result => {
                if (result.ret_code === 0) {
                    this.router.navigate(['/login'], {
                        queryParams: {"email": this.model.email}
                    });
                } else {
                    if (result['errors']) {
                        this.captchaService.refresh(this.captcha_refresh_captcha);
                        Object.keys(result['errors']).forEach(key => {
                            if (result['errors'][key] instanceof Array) {
                                result['errors'][key].forEach(error => {
                                    this.errors.push(error);
                                });
                            }
                        });
                    }
                }
            });
        }
    }


}
