import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-field-simditor',
    template: `
    <textarea class="form-control"
              app-simditor
              (valueChange)="valueChange($event)"
              [value]="model[key]"
           [options]="options">
    </textarea>
   <input type="hidden"
   [id]="id"
   [value]="model[key]"
   [formControl]="formControl" 
   [formlyAttributes]="field"
   >
  `,
})
export class FormlyFieldSimditor extends FieldType {
    ngOnInit() {
        if (!(this.to.attributes instanceof Object)) {
            this.to.attributes = {};
        }
        Object.assign(this.to.attributes, {
            singleDatePicker: true,
            showDropdowns: true
        }, this.to.attributes);
    }

    valueChange(value) {
        if (!value) {
            value = undefined;
        }
        this.model[this.key] = value;
    }
}