<nz-upload
        nzAction="web/mtp/file-upload"
        [nzShowUploadList]="false"
        [nzDisabled]="to['disabled']"
        [nzBeforeUpload]="valid"
        (nzChange)="onChange($event)">
    <button nz-button type="button" style="height: 83px;" [ngStyle]="{'cursor': to['disabled'] ? 'not-allowed' : 'pointer'}">
        <div *ngIf="file">
            <ng-container *ngIf="['image/gif','image/png','image/jpg','image/jpeg','image/bmp'].indexOf(file.file_type)>-1; else normalFile">
                <img [src]="'web/mtp/file-render?id='+file.id" [alt]="file.file_name">
            </ng-container>
            <ng-template #normalFile><i class="fa fa-link mr-2"></i>{{file.file_name}}</ng-template>

        </div>
        <span>
            <ng-container *ngIf="!file && !progress">{{to['button_text'] || 'Upload'}}</ng-container>
            <nz-progress nzWidth="50" [nzPercent]="progress" *ngIf="progress" nzType="circle"></nz-progress>
        </span>

    </button>
</nz-upload>
