/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./member-picker.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../directive/member-picker/member-picker.component.ngfactory";
import * as i3 from "../../directive/member-picker/member-picker.component";
import * as i4 from "@angular/common/http";
import * as i5 from "../../services/cache.service";
import * as i6 from "./member-picker";
var styles_FormlyFieldMemberPicker = [i0.styles];
var RenderType_FormlyFieldMemberPicker = i1.ɵcrt({ encapsulation: 0, styles: styles_FormlyFieldMemberPicker, data: {} });
export { RenderType_FormlyFieldMemberPicker as RenderType_FormlyFieldMemberPicker };
export function View_FormlyFieldMemberPicker_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], [[2, "has-error", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-member-picker", [], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = (_co.valueChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MemberPickerComponent_0, i2.RenderType_MemberPickerComponent)), i1.ɵdid(2, 638976, null, 0, i3.MemberPickerComponent, [i4.HttpClient, i5.CacheService, i1.ElementRef], { value: [0, "value"] }, { valueChange: "valueChange" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.model[_co.key]; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showError; _ck(_v, 0, 0, currVal_0); }); }
export function View_FormlyFieldMemberPicker_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "formly-member-picker", [], null, null, null, View_FormlyFieldMemberPicker_0, RenderType_FormlyFieldMemberPicker)), i1.ɵdid(1, 16760832, null, 0, i6.FormlyFieldMemberPicker, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormlyFieldMemberPickerNgFactory = i1.ɵccf("formly-member-picker", i6.FormlyFieldMemberPicker, View_FormlyFieldMemberPicker_Host_0, { form: "form", field: "field", model: "model", options: "options" }, {}, []);
export { FormlyFieldMemberPickerNgFactory as FormlyFieldMemberPickerNgFactory };
