var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FieldType } from '@ngx-formly/core';
var FormlyFieldSimditor = /** @class */ (function (_super) {
    __extends(FormlyFieldSimditor, _super);
    function FormlyFieldSimditor() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FormlyFieldSimditor.prototype.ngOnInit = function () {
        if (!(this.to.attributes instanceof Object)) {
            this.to.attributes = {};
        }
        Object.assign(this.to.attributes, {
            singleDatePicker: true,
            showDropdowns: true
        }, this.to.attributes);
    };
    FormlyFieldSimditor.prototype.valueChange = function (value) {
        if (!value) {
            value = undefined;
        }
        this.model[this.key] = value;
    };
    return FormlyFieldSimditor;
}(FieldType));
export { FormlyFieldSimditor };
