import {Component, ViewEncapsulation} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FormlyFormOptions, FormlyFieldConfig} from '@ngx-formly/core';
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {CaptchaService} from "../events/captcha.service";
import {UserService} from "../../api-client";
import {TranslateService} from "@ngx-translate/core";
import {forkJoin} from "rxjs/internal/observable/forkJoin";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./login.component.css']
})
export class LoginComponent {
    form = new FormGroup({});
    errors = [];
    model: any = {};
    options: FormlyFormOptions = {};
    return_url;
    captcha_refresh_captcha = 'web/user/captcha?refresh';
    login_url;

    fields: FormlyFieldConfig[];

    constructor(private http: HttpClient,
                private route: ActivatedRoute,
                private captchaService: CaptchaService,
                private userService: UserService,
                private translate: TranslateService,
                private router: Router) {

        forkJoin([
            this.translate.get('username/email'),
            this.translate.get('password'),
            this.translate.get('please input captcha'),
        ]).subscribe(results => {
            this.fields = [
                {
                    key: 'username',
                    type: 'input',
                    className: "username login-input-group",
                    templateOptions: {
                        placeholder: results[0],
                        required: true,
                    },
                },
                {
                    key: 'password',
                    type: 'input',
                    className: "password login-input-group",
                    templateOptions: {
                        placeholder: results[1],
                        type: 'password',
                        required: true,
                        minLength: 6,
                    },
                },
                {
                    key: 'captcha',
                    type: 'captcha',
                    className: "captcha login-input-group",
                    templateOptions: {
                        placeholder: results[2],
                        attributes: {
                            src: 'web/user/captcha',
                            refresh_src: this.captcha_refresh_captcha
                        }
                    },
                }
            ];
        });

    };

    ngOnInit() {
        const re_url = this.route.snapshot.queryParams['return_url'];
        var urlReg = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
        if (re_url.match(urlReg)) {
            this.return_url = encodeURIComponent(re_url);
        } else {
            this.return_url = '';
        }
        console.log(this.return_url)
        let base_url = '';
        switch (location.hostname) {
            case 'oops.cm.com':
                base_url = 'http://oops.cm.com:8200/';
                break;
        }
        this.login_url = base_url + 'site/login?return_url=' + this.return_url;
    }

    submit() {
        if (this.form.valid) {
            this.errors = [];
            this.userService.webUserLoginPost(this.model).subscribe(result => {
                if (result.ret_code === 0) {
                    this.router.navigate(['/']);
                } else {
                    if (result['errors']) {
                        this.captchaService.refresh(this.captcha_refresh_captcha);
                        Object.keys(result['errors']).forEach(key => {
                            if (result['errors'][key] instanceof Array) {
                                result['errors'][key].forEach(error => {
                                    this.errors.push(error);
                                });
                            }
                        });
                    }
                }
            });
        }
    }
}
