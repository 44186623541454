import { Injectable } from '@angular/core';
import { CoolLocalStorage } from 'angular2-cool-storage';

@Injectable()
export class CacheService {
    localStorage: CoolLocalStorage;
    promises = {};

    constructor(localStorage: CoolLocalStorage) {
        this.localStorage = localStorage;
    }

    remove(key) {
        this.localStorage.removeItem(key);
        return this;
    }

    set(key, value) {
        let cache_data = {
            value,
            expiration_time: (new Date()).getTime()
        };
        this.localStorage.setItem(key, JSON.stringify(cache_data));
        return this;
    }

    get(key) {
        const result = this.localStorage.getItem(key);
        if (result) {
            let cache_data = JSON.parse(result);
            if (!cache_data.expiration_time) {
                return null;
            }
            let is_expiration = ((new Date).getTime() - cache_data.expiration_time) > 30 * 60 * 100;
            if (is_expiration) {
                return null;
            }
            return cache_data.value;
        }
        return result;
    }

    from(key, callback) {
        const result = this.get(key);
        const vm = this;
        let promise;
        if (result) {
            promise = new Promise((resolve) => {
                resolve(result);
            });
        } else {
            if (this.promises[key]) {
                return this.promises[key];
            }
            promise = callback().then(function (res) {
                vm.set(key, res);
                return res;
            });
        }
        this.promises[key] = promise;
        return promise;
    }

    clear() {
        return this.localStorage.clear();
    }
}

