import { FormlyFieldConfig } from '@ngx-formly/core';
import { zh_CN } from 'ng-zorro-antd';
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
registerLocaleData(zh);
export function maxlengthFunction(error, field) {
    return '超出最大长度' + field.templateOptions.maxLength;
}
export function fileSizeFunction(error, field) {
    if (field.templateOptions['file_size_error_message']) {
        return field.templateOptions['file_size_error_message'];
    }
    return '文件大小超出限制';
}
export function fileTypeFunction(error, field) {
    if (field.templateOptions['file_type_error_message']) {
        return field.templateOptions['file_type_error_message'];
    }
    return '文件类型不匹配';
}
var ɵ0 = zh_CN;
var FormlyComponentModule = /** @class */ (function () {
    function FormlyComponentModule() {
    }
    return FormlyComponentModule;
}());
export { FormlyComponentModule };
export { ɵ0 };
