import { ElementRef, EventEmitter } from '@angular/core';
import * as jQuery from 'jquery';
var SimditorDirective = /** @class */ (function () {
    function SimditorDirective(elementRef) {
        this.elementRef = elementRef;
        this.valueChange = new EventEmitter();
    }
    Object.defineProperty(SimditorDirective.prototype, "value", {
        set: function (value) {
            if (this._value != value) {
                this._value = value || '';
                if (this._editor) {
                    this._editor.setValue(this._value);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    SimditorDirective.prototype.ngAfterContentInit = function () {
        var _this = this;
        var $calendarElement = jQuery(this.elementRef.nativeElement);
        var options = {};
        if (this.options instanceof Object) {
            Object.assign(options, this.options);
        }
        // $calendarElement['daterangepicker'](options);
        require.ensure(['simditor'], function (require) {
            var Simditor = require('simditor');
            var editor = new Simditor({
                textarea: $calendarElement
                //optional options
            });
            var vm = _this;
            if (_this._value) {
                editor.setValue(_this._value);
            }
            editor.on('valuechanged', function () {
                vm._value = editor.getValue();
                vm.valueChange.emit(editor.getValue());
            });
            editor.on('selectionchanged', function () {
                vm._value = editor.getValue();
                vm.valueChange.emit(editor.getValue());
            });
            _this._editor = editor;
        });
    };
    return SimditorDirective;
}());
export { SimditorDirective };
