var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { Field } from '@ngx-formly/core';
import { HttpClient } from "@angular/common/http";
import { CaptchaService } from "../events/captcha.service";
var FormlyFieldCaptcha = /** @class */ (function (_super) {
    __extends(FormlyFieldCaptcha, _super);
    function FormlyFieldCaptcha(http, service) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.service = service;
        return _this;
    }
    FormlyFieldCaptcha.prototype.ngOnInit = function () {
        var _this = this;
        this.src = this.to.attributes.src;
        this.service.refresh$.subscribe(function () {
            _this.src = _this.to.attributes.src + '?random=' + Math.random();
        });
    };
    FormlyFieldCaptcha.prototype.reload = function () {
        this.service.refresh(this.to.attributes.refresh_src);
    };
    return FormlyFieldCaptcha;
}(Field));
export { FormlyFieldCaptcha };
