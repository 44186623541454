var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FieldType } from '@ngx-formly/core';
import { DatePipe } from '../../../../node_modules/@angular/common';
var FormlyFieldDate = /** @class */ (function (_super) {
    __extends(FormlyFieldDate, _super);
    function FormlyFieldDate(datePipe) {
        var _this = _super.call(this) || this;
        _this.datePipe = datePipe;
        return _this;
    }
    FormlyFieldDate.prototype.ngOnInit = function () {
        if (!(this.to.attributes instanceof Object)) {
            this.to.attributes = {};
        }
        Object.assign(this.to.attributes, {
            singleDatePicker: true,
        }, this.to.attributes);
    };
    FormlyFieldDate.prototype.onChange = function (date) {
        this.model[this.key] = this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
        this.formControl.setValue(this.model[this.key]);
    };
    return FormlyFieldDate;
}(FieldType));
export { FormlyFieldDate };
