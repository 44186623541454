/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../directive/simditor/simditor.directive";
import * as i2 from "@angular/forms";
import * as i3 from "@ngx-formly/core";
import * as i4 from "./simditor";
var styles_FormlyFieldSimditor = [];
var RenderType_FormlyFieldSimditor = i0.ɵcrt({ encapsulation: 2, styles: styles_FormlyFieldSimditor, data: {} });
export { RenderType_FormlyFieldSimditor as RenderType_FormlyFieldSimditor };
export function View_FormlyFieldSimditor_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "textarea", [["app-simditor", ""], ["class", "form-control"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = (_co.valueChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 1064960, null, 0, i1.SimditorDirective, [i0.ElementRef], { value: [0, "value"], options: [1, "options"] }, { valueChange: "valueChange" }), (_l()(), i0.ɵted(-1, null, ["    "])), (_l()(), i0.ɵeld(3, 0, null, null, 6, "input", [["type", "hidden"]], [[8, "id", 0], [8, "value", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "focus"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 4)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 4)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("focus" === en)) {
        var pd_4 = (i0.ɵnov(_v, 9).onFocus() !== false);
        ad = (pd_4 && ad);
    } if (("blur" === en)) {
        var pd_5 = (i0.ɵnov(_v, 9).onBlur() !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 16384, null, 0, i2.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i0.ɵdid(6, 540672, null, 0, i2.FormControlDirective, [[8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_q]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlDirective]), i0.ɵdid(8, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), i0.ɵdid(9, 540672, null, 0, i3.FormlyAttributes, [i0.Renderer2, i0.ElementRef], { field: [0, "field"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model[_co.key]; var currVal_1 = _co.options; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_11 = _co.formControl; _ck(_v, 6, 0, currVal_11); var currVal_12 = _co.field; _ck(_v, 9, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.id; var currVal_3 = _co.model[_co.key]; var currVal_4 = i0.ɵnov(_v, 8).ngClassUntouched; var currVal_5 = i0.ɵnov(_v, 8).ngClassTouched; var currVal_6 = i0.ɵnov(_v, 8).ngClassPristine; var currVal_7 = i0.ɵnov(_v, 8).ngClassDirty; var currVal_8 = i0.ɵnov(_v, 8).ngClassValid; var currVal_9 = i0.ɵnov(_v, 8).ngClassInvalid; var currVal_10 = i0.ɵnov(_v, 8).ngClassPending; _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }); }
export function View_FormlyFieldSimditor_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "formly-field-simditor", [], null, null, null, View_FormlyFieldSimditor_0, RenderType_FormlyFieldSimditor)), i0.ɵdid(1, 16760832, null, 0, i4.FormlyFieldSimditor, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormlyFieldSimditorNgFactory = i0.ɵccf("formly-field-simditor", i4.FormlyFieldSimditor, View_FormlyFieldSimditor_Host_0, { form: "form", field: "field", model: "model", options: "options" }, {}, []);
export { FormlyFieldSimditorNgFactory as FormlyFieldSimditorNgFactory };
