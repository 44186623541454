<div class="oops-header">
    <div class="warp-default">
        <div class="head-left">
            <a href="#" class="logo">
                <img src="assets/images/logo-en.svg" i18n-src>
            </a>
        </div>
        <div class="head-right">
            <ul class="topbar-nav">
                <li class="icon">
                    <a href="#">
                        <i class="fa fa-search"></i>
                    </a>
                </li>
                <li class="action">
                </li>
                <li class="user">
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="oops-login">
    <div class="login-container">
        <div class="login-head">
            <nav class="nav nav-login-way">
                <a class="nav-link active" href="#" i18n>update password</a>
            </nav>
        </div>
        <div class="login-body">
            <div class="alert alert-info" i18n>send mail success,please check your mail.</div>
            <div class="alert alert-danger" *ngIf="errors.length">
                <p *ngFor="let error of errors">{{error}}</p>
            </div>
            <form [formGroup]="form" (ngSubmit)="submit()">
                <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form">
                    <div class="login-btn row">
                        <div class="col-md-6">
                            <a [routerLink]="['/login']" class="btn btn-link text-left" style="margin-right: 10px;"
                               i18n>login</a>
                        </div>
                        <div class="col-md-6">
                            <button type="submit" class="btn btn-primary btn-lg" i18n>update password</button>
                        </div>
                    </div>
                </formly-form>
            </form>
        </div>
    </div>
</div>