var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FieldType } from '@ngx-formly/core';
var FormlyFieldNzSelect = /** @class */ (function (_super) {
    __extends(FormlyFieldNzSelect, _super);
    function FormlyFieldNzSelect() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(FormlyFieldNzSelect.prototype, "labelProp", {
        get: function () {
            return this.to['labelProp'] || 'label';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormlyFieldNzSelect.prototype, "valueProp", {
        get: function () {
            return this.to['valueProp'] || 'value';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormlyFieldNzSelect.prototype, "mode", {
        get: function () {
            return this.to['mode'] || 'default';
        },
        enumerable: true,
        configurable: true
    });
    return FormlyFieldNzSelect;
}(FieldType));
export { FormlyFieldNzSelect };
