/**
 * 接口文档
 * Version: __0.0.1__
 *
 * OpenAPI spec version: 0.0.1
 * Contact: binghuiluo@tencent.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/internal/Observable';

import { InlineResponse20016 } from '../model/inlineResponse20016';
import { InlineResponse20017 } from '../model/inlineResponse20017';
import { InlineResponse20019 } from '../model/inlineResponse20019';
import { InlineResponse20020 } from '../model/inlineResponse20020';
import { InlineResponse20021 } from '../model/inlineResponse20021';
import { InlineResponse20022 } from '../model/inlineResponse20022';
import { InlineResponse20023 } from '../model/inlineResponse20023';
import { InlineResponse20024 } from '../model/inlineResponse20024';
import { InlineResponse20025 } from '../model/inlineResponse20025';
import { InlineResponse20026 } from '../model/inlineResponse20026';
import { InlineResponse20027 } from '../model/inlineResponse20027';
import { InlineResponse2003 } from '../model/inlineResponse2003';
import { InlineResponse2006 } from '../model/inlineResponse2006';
import { WorkflowCompleteTask } from '../model/workflowCompleteTask';
import { WorkflowCreate } from '../model/workflowCreate';
import { WorkflowQuery } from '../model/workflowQuery';
import { WorkflowTaskAssigneeUpdate } from '../model/workflowTaskAssigneeUpdate';
import { WorkflowTaskDataUpdate } from '../model/workflowTaskDataUpdate';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class WorkflowInstanceService {

    protected basePath = 'http://oops.cm.com:8200';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 获取任务名列表
     * 获取任务名列表
     * @param work_flow_id 
     * @param work_flow_id_list 半角逗号分隔，覆盖work_flow_id数据
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webWorkflowInstanceAllTaskNamesPost(work_flow_id?: number, work_flow_id_list?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20016>;
    public webWorkflowInstanceAllTaskNamesPost(work_flow_id?: number, work_flow_id_list?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20016>>;
    public webWorkflowInstanceAllTaskNamesPost(work_flow_id?: number, work_flow_id_list?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20016>>;
    public webWorkflowInstanceAllTaskNamesPost(work_flow_id?: number, work_flow_id_list?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (work_flow_id !== undefined) {
            queryParameters = queryParameters.set('work_flow_id', <any>work_flow_id);
        }
        if (work_flow_id_list !== undefined) {
            queryParameters = queryParameters.set('work_flow_id_list', <any>work_flow_id_list);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.post<InlineResponse20016>(`${this.basePath}/web/workflow-instance/all-task-names`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 完成流程任务
     * 完成流程任务
     * @param WorkflowCompleteTask 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webWorkflowInstanceCompleteTaskPost(WorkflowCompleteTask?: WorkflowCompleteTask, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20023>;
    public webWorkflowInstanceCompleteTaskPost(WorkflowCompleteTask?: WorkflowCompleteTask, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20023>>;
    public webWorkflowInstanceCompleteTaskPost(WorkflowCompleteTask?: WorkflowCompleteTask, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20023>>;
    public webWorkflowInstanceCompleteTaskPost(WorkflowCompleteTask?: WorkflowCompleteTask, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse20023>(`${this.basePath}/web/workflow-instance/complete-task`,
            WorkflowCompleteTask,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 获取已授权创建的产品列表
     * 获取已授权创建的产品列表
     * @param work_flow_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webWorkflowInstanceCreateAuthProductListPost(work_flow_id?: number, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2006>;
    public webWorkflowInstanceCreateAuthProductListPost(work_flow_id?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2006>>;
    public webWorkflowInstanceCreateAuthProductListPost(work_flow_id?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2006>>;
    public webWorkflowInstanceCreateAuthProductListPost(work_flow_id?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (work_flow_id !== undefined) {
            queryParameters = queryParameters.set('work_flow_id', <any>work_flow_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.post<InlineResponse2006>(`${this.basePath}/web/workflow-instance/create-auth-product-list`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 获取已授权创建的流程列表
     * 获取已授权创建的流程列表
     * @param data_table_name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webWorkflowInstanceCreateAuthWorkflowListPost(data_table_name?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20027>;
    public webWorkflowInstanceCreateAuthWorkflowListPost(data_table_name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20027>>;
    public webWorkflowInstanceCreateAuthWorkflowListPost(data_table_name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20027>>;
    public webWorkflowInstanceCreateAuthWorkflowListPost(data_table_name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (data_table_name !== undefined) {
            queryParameters = queryParameters.set('data_table_name', <any>data_table_name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.post<InlineResponse20027>(`${this.basePath}/web/workflow-instance/create-auth-workflow-list`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 返回流程单据列表
     * 返回流程单据列表
     * @param WorkflowCreate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webWorkflowInstanceCreatePost(WorkflowCreate?: WorkflowCreate, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20020>;
    public webWorkflowInstanceCreatePost(WorkflowCreate?: WorkflowCreate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20020>>;
    public webWorkflowInstanceCreatePost(WorkflowCreate?: WorkflowCreate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20020>>;
    public webWorkflowInstanceCreatePost(WorkflowCreate?: WorkflowCreate, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse20020>(`${this.basePath}/web/workflow-instance/create`,
            WorkflowCreate,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 返回流程单据列表
     * 返回流程单据列表
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webWorkflowInstanceCreateTemplatePost(id?: number, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20021>;
    public webWorkflowInstanceCreateTemplatePost(id?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20021>>;
    public webWorkflowInstanceCreateTemplatePost(id?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20021>>;
    public webWorkflowInstanceCreateTemplatePost(id?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.post<InlineResponse20021>(`${this.basePath}/web/workflow-instance/create-template`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 删除流程单据
     * 删除流程单据
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webWorkflowInstanceDeletePost(id?: number, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2003>;
    public webWorkflowInstanceDeletePost(id?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2003>>;
    public webWorkflowInstanceDeletePost(id?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2003>>;
    public webWorkflowInstanceDeletePost(id?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.post<InlineResponse2003>(`${this.basePath}/web/workflow-instance/delete`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 返回流程单据列表
     * 返回流程单据列表
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webWorkflowInstanceDetailPost(id?: number, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20022>;
    public webWorkflowInstanceDetailPost(id?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20022>>;
    public webWorkflowInstanceDetailPost(id?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20022>>;
    public webWorkflowInstanceDetailPost(id?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.post<InlineResponse20022>(`${this.basePath}/web/workflow-instance/detail`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 获取严重程度的数据列表
     * 获取严重程度的数据列表
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webWorkflowInstanceInputListPost(observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20017>;
    public webWorkflowInstanceInputListPost(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20017>>;
    public webWorkflowInstanceInputListPost(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20017>>;
    public webWorkflowInstanceInputListPost(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.post<InlineResponse20017>(`${this.basePath}/web/workflow-instance/input-list`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 是否有删除工单权限
     * 是否有删除工单权限
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webWorkflowInstanceIsPermissionPost(id?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2003>;
    public webWorkflowInstanceIsPermissionPost(id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2003>>;
    public webWorkflowInstanceIsPermissionPost(id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2003>>;
    public webWorkflowInstanceIsPermissionPost(id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.post<InlineResponse2003>(`${this.basePath}/web/workflow-instance/is-permission`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 返回流程单据列表
     * 返回流程单据列表
     * @param WorkflowQuery 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webWorkflowInstancePost(WorkflowQuery?: WorkflowQuery, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20019>;
    public webWorkflowInstancePost(WorkflowQuery?: WorkflowQuery, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20019>>;
    public webWorkflowInstancePost(WorkflowQuery?: WorkflowQuery, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20019>>;
    public webWorkflowInstancePost(WorkflowQuery?: WorkflowQuery, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse20019>(`${this.basePath}/web/workflow-instance`,
            WorkflowQuery,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 移除流程实例
     * 移除流程实例
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webWorkflowInstanceRemovePost(id?: number, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20024>;
    public webWorkflowInstanceRemovePost(id?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20024>>;
    public webWorkflowInstanceRemovePost(id?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20024>>;
    public webWorkflowInstanceRemovePost(id?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any) };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (id !== undefined) {
            formParams = formParams.append('id', <any>id) || formParams;
        }

        return this.httpClient.post<InlineResponse20024>(`${this.basePath}/web/workflow-instance/remove`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 更新任务待办人
     * 更新任务待办人
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webWorkflowInstanceTaskAssigneeUpdatePost(id?: WorkflowTaskAssigneeUpdate, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20025>;
    public webWorkflowInstanceTaskAssigneeUpdatePost(id?: WorkflowTaskAssigneeUpdate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20025>>;
    public webWorkflowInstanceTaskAssigneeUpdatePost(id?: WorkflowTaskAssigneeUpdate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20025>>;
    public webWorkflowInstanceTaskAssigneeUpdatePost(id?: WorkflowTaskAssigneeUpdate, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse20025>(`${this.basePath}/web/workflow-instance/task-assignee-update`,
            id,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 更新任务待办人
     * 更新任务待办人
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public webWorkflowInstanceTaskDataUpdatePost(id?: WorkflowTaskDataUpdate, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20026>;
    public webWorkflowInstanceTaskDataUpdatePost(id?: WorkflowTaskDataUpdate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20026>>;
    public webWorkflowInstanceTaskDataUpdatePost(id?: WorkflowTaskDataUpdate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20026>>;
    public webWorkflowInstanceTaskDataUpdatePost(id?: WorkflowTaskDataUpdate, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse20026>(`${this.basePath}/web/workflow-instance/task-data-update`,
            id,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
