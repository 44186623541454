import { Component } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { PiwikService } from "./services/piwik.service";
import { NavigationEnd, Router } from "@angular/router";
import { LanguageService } from "./services/language.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'app';

    constructor(
        translate: TranslateService,
        private router: Router,
        private piwik: PiwikService,
    ) {
        this.setPiwik();
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.setPiwik();
            }
        });
        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang('en-Us');
        let language = LanguageService.language;
        if (language == 'zh-Hans') {
            translate.use('zh-Hans');
        } else {
            translate.use('en-Us');
        }
    }

    setPiwik() {
        if (this.router.url !== '/building' && location.host === 'oops.qq.com') {
            this.piwik.init('piwik.php', 9);
            this.piwik.track();
        }
    }
}
